// @flow
import { Box, Typography } from '@material-ui/core';
import React from 'react';
import Cases from '../shared/Cases';
import { caseStudiesStyles } from './styles';

const CaseStudies = ({ casesIds }: { casesIds: Array<string> }) => {
  const classes = caseStudiesStyles({});

  return (
    <Box id="case_study" className={classes.wrapper}>
      <Box className={classes.container} textAlign="center">
        <Typography variant="h2" component="h4" className={classes.title}>
          導入事例
        </Typography>
      </Box>
      <Cases casesIds={casesIds} />
    </Box>
  );
};

export default CaseStudies;
