// @flow
import { Box, Container, Typography } from '@material-ui/core';
import React from 'react';
import { companyStyles } from './styles';

const Goods = ({
  title,
  subTitle,
  children,
}: {
  title: string,
  subTitle: string,
  children: Object,
}) => {
  const classes = companyStyles({});

  return (
    <section id="company">
      <Box className={classes.container}>
        <Container maxWidth="md">
          <div>
            <Typography variant="body1" className={classes.title}>
              {title}
            </Typography>
            <Box textAlign="center" className={classes.wrapper}>
              <Typography
                variant="h2"
                component="h2"
                className={classes.subtitle}
              >
                {subTitle}
              </Typography>
            </Box>
          </div>
          {children}
        </Container>
      </Box>
    </section>
  );
};

export default Goods;
