// @flow
import { Box, Typography, Container } from '@material-ui/core';
import React from 'react';
import { introStyles } from './styles';

const IntroBlock = ({
  title,
  subTitle,
  text,
}: {
  title?: string,
  subTitle?: string,
  text: string,
}) => {
  const classes = introStyles({});

  return (
    <div className={classes.container}>
      <Container maxWidth="md">
        <div className={classes.wrapper}>
          {title && (
            <Typography variant="h2" component="h4" className={classes.title}>
              {title}
            </Typography>
          )}
          {subTitle && (
            <Box className={classes.subtitleWrapper}>
              <Typography
                variant="h4"
                component="div"
                className={classes.subtitle}
              >
                {subTitle}
              </Typography>
            </Box>
          )}
          <Box textAlign="left" className={classes.textWrapper}>
            <Typography
              variant="body1"
              component="p"
              dangerouslySetInnerHTML={{ __html: text }}
            />
          </Box>
        </div>
      </Container>
    </div>
  );
};

IntroBlock.defaultProps = {
  title: '',
  subTitle: '',
};

export default IntroBlock;
