// @flow
import React from 'react';
import IntroBlock from '../shared/IntroBlock';

const Intro = () => (
  <div id="intro">
    <IntroBlock
      title="導入のポイント"
      subTitle="若年層の取り込みに"
      text="昨今、一気に認知・人気ともに上昇中のサブスク。ユーザーにはクレカを持たない若年層が多く見られます。クレカいらずスマホで決済できるPaidyなら、ユーザーとの親和性が高く、取り込みに最適です。"
    />
  </div>
);

export default Intro;
