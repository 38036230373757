/* eslint-disable import/prefer-default-export */
export const blocks = [
  {
    id: 'company',
    title: '導入企業',
  },
  {
    id: 'intro',
    title: '導入のポイント',
  },
  {
    id: 'case_study',
    title: '導入事例',
  },
  {
    id: 'start',
    title: 'ペイディを始める',
  },
];
