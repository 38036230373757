// @flow
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import CarouselCaseStudy from '../../../components/Carousel/CarouselCaseStudy';
import { fetchCaseStudies } from '../../../redux/case-study';
import { fetchMerchants } from '../../../redux/merchant';
import { fetchCategories } from '../../../redux/category';

const mapStateToProps = (state) => ({
  caseStudies: state.caseStudy.data,
  merchants: state.merchant.data,
  cats: state.category.data,
});

const mapDispatchToProps = (dispatch: Function) => ({
  _fetchCaseStudies: () => dispatch(fetchCaseStudies()),
  _fetchMerchants: () => dispatch(fetchMerchants()),
  _fetchCategories: () => dispatch(fetchCategories()),
});

// $FlowFixMe
const Cases = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  ({
    _fetchCaseStudies,
    _fetchMerchants,
    _fetchCategories,
    caseStudies,
    merchants,
    cats,
    categories,
    settings,
    exceptId,
    casesIds,
  }: {
    _fetchCaseStudies: Function,
    _fetchMerchants: Function,
    _fetchCategories: Function,
    caseStudies: Array<Object>,
    merchants: Array<Object>,
    cats: Object,
    categories: Array<string>,
    settings: Object,
    exceptId: Boolean,
    casesIds: Array<string>,
  }) => {
    useEffect(() => {
      _fetchCategories();
      _fetchMerchants();
      _fetchCaseStudies();
    }, []);

    return (
      <CarouselCaseStudy
        caseStudies={caseStudies}
        merchants={merchants}
        cats={cats}
        categories={categories}
        customSettings={settings}
        exceptId={exceptId}
        casesIds={casesIds}
      />
    );
  }
);

export default Cases;
