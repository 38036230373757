/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core/styles';

export const navigationStyles = makeStyles(() => ({
  pc: {
    display: 'block',
    '@media (max-width: 767.98px)': {
      display: 'none',
    },
  },
  sp: {
    display: 'none',
    '@media (max-width: 767.98px)': {
      display: 'block',
    },
  },
}));
