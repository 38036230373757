/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import intersection from 'lodash.intersection';
import Slider from 'react-slick';
import { Link } from 'gatsby';
// import cx from 'classnames';
import { Image } from 'react-image-and-background-image-fade';
import { catToDisplay } from '../../utils/merchant';

import './Carousel.scss';
import styles from './CarouselCaseStudy.module.scss';

const defaultSettings = {
  autoplay: false,
  autoplaySpeed: 5000,
  slidesToShow: 3,
  slidesToScroll: 3,
  // centerPadding: '30px',
  swipe: true,
  infinite: true,
  initialSlide: 0,
  dots: true,
  arrows: false,
  customPaging: () => {
    return <button></button>;
  },
  nextArrow: null,
  prevArrow: null,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const Slide = ({
  imgSrc,
  shopName,
  title,
  firstIntervieweeName,
  secondIntervieweeName,
  parentCat,
  slug,
}: {
  imgSrc: string,
  shopName: string,
  title: string,
  firstIntervieweeName: string,
  secondIntervieweeName: string,
  parentCat: string,
  slug: string,
}) => (
  <Link
    to={`/merchant/case/${slug}/`}
    className={styles.carouselCaseStudy}
    // title={shopName}
  >
    <figure>
      <Image src={imgSrc} alt={title} width="360px" height="207px" lazyLoad />
    </figure>
    <article>
      <span className={styles.parentCat}>{parentCat}</span>
      <h4>{shopName}</h4>
      <h5>
        {firstIntervieweeName} {secondIntervieweeName}
      </h5>

      <h6>{title && title.replace(/\|/g, '')}</h6>
    </article>
  </Link>
);

const Carousel = ({
  caseStudies,
  merchants,
  cats,
  categories,
  customSettings,
  exceptId,
  casesIds,
}: {
  caseStudies: Array<Object>,
  merchants: Array<Object>,
  cats: Array<Object>,
  categories: Array<string>,
  customSettings: Object,
  exceptId: Boolean,
  casesIds: Array<string>,
}) => {
  let caseStudiesFiltered = caseStudies;

  if (!caseStudies || !merchants || !cats) {
    return null;
  }

  if (categories && categories.length) {
    caseStudiesFiltered = caseStudies
      .filter((c) => intersection(c.catSlugs, categories).length)
      .filter((c) => c.slug !== exceptId);
  }

  if (casesIds && casesIds.length) {
    caseStudiesFiltered = caseStudies
      .filter((c) => casesIds.includes(c.slug))
      .filter((c) => c.slug !== exceptId)
      .sort(
        (x, y) =>
          casesIds.findIndex((shopName) => shopName === x.shopName) -
          casesIds.findIndex((shopName) => shopName === y.shopName)
      );
  }

  if (caseStudiesFiltered.length < defaultSettings.slidesToShow) {
    defaultSettings.infinite = false;
  } else {
    defaultSettings.infinite = true;
  }

  const settings = { ...defaultSettings, ...customSettings };

  return (
    <section className={styles.carouselCaseStudySection}>
      <Slider {...settings}>
        {caseStudiesFiltered.map((c) => {
          return (
            <Slide
              key={c.id}
              imgSrc={c.thumbnail.url}
              colorLogo={c.colorLogo || (c.merchant && c.merchant.colorLogo)}
              shopName={c.shopName}
              title={c.title}
              firstIntervieweeName={c.firstIntervieweeName}
              secondIntervieweeName={c.secondIntervieweeName}
              parentCat={
                c.caseStudyCategory
                  ? c.caseStudyCategory.title
                  : catToDisplay(c.catSlugs, cats)
              }
              slug={c.slug}
            />
          );
        })}
      </Slider>
    </section>
  );
};

export default Carousel;
